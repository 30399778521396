<template>
    <v-container>
        <v-row>
            <v-col
                cols="12"
                class="d-flex flex-row align-center justify-center"
            >
                <v-btn-toggle v-model="filter" mandatory>
                    <v-btn value="inbox">
                        <v-icon>mdi-inbox</v-icon>
                    </v-btn>
                    <v-btn value="archived">
                        <v-icon>mdi-archive</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
            <v-col cols="12">
                <v-expansion-panels popout v-if="messages && messages.length">
                    <v-expansion-panel
                        v-for="message in messages"
                        :key="message.id"
                        hide-actions
                    >
                        <v-expansion-panel-header @click="onExpand(message)">
                            <v-row align="start" class="spacer" no-gutters>
                                <v-col cols="2">
                                    <v-icon
                                        v-if="message.state === 'unread'"
                                        color="primary"
                                        >mdi-email</v-icon
                                    >
                                </v-col>

                                <v-col class="text-no-wrap" cols="10">
                                    <div>
                                        <strong>{{ message.subject }}</strong>
                                    </div>
                                    <div>
                                        {{
                                            message.timestamp
                                                .toDate()
                                                .toLocaleString()
                                        }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <v-card-text>{{ message.body }}</v-card-text>
                            <v-row
                                v-if="message.state === 'read'"
                                class="py-2"
                                justify="end"
                            >
                                <v-btn
                                    text
                                    color="primary"
                                    @click="onDelete(message.id)"
                                >
                                    Archive
                                </v-btn>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            filter: 'unread',
        }
    },
    computed: {
        messages() {
            let messages = this.$store.getters['messages/getMessages']
            messages = messages.slice()
            messages = messages.sort(
                (a, b) => b.timestamp.seconds - a.timestamp.seconds
            )
            let allowedStates = ['unread', 'read']
            if (this.filter === 'read') {
                allowedStates = ['read']
            } else if (this.filter === 'archived') {
                allowedStates = ['archived']
            }
            return messages.filter(
                (message) =>
                    message.state == undefined ||
                    allowedStates.includes(message.state)
            )
        },
    },
    methods: {
        onExpand(message) {
            if (message.state == undefined || message.state === 'unread') {
                this.$store.dispatch('messages/setState', {
                    id: message.id,
                    messageState: 'read',
                })
            }
        },
        onDelete(id) {
            this.$store.dispatch('messages/setState', {
                id,
                messageState: 'archived',
            })
        },
    },
}
</script>

<style scoped></style>
